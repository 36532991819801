.coswin8i-part-2{
    background:  url('../Innovative_Products/Coswin/1st-Page-BG-Image-01.png');
    background-size: cover;
}
.coswin8i-part-3{
    background:  url('../Innovative_Products/Coswin/2nd-Page-BG-Image-01.png');
    background-size: cover;
}
.coswin8i-part-5{
    background:  url('../Innovative_Products/Coswin/3rd-Page-BG-Image-01.png');
    background-size: cover;
}