.drop-tab1 {
    float: left;
    border: 1px solid #ccc;
    background-color: #fff;
    width: 27%;
    height: 390px;
   

  }
  .drop-tab2 {
    float: left;
    border: 1px solid #ccc;
    background-color: #fff;
    width: 27%;
    height: 390px;
   
  }

  
  .drop-tab1 button {
    display: block;
  background-color: inherit;
  color: black;
  /* padding: 20px 15px; */
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  line-height: 6px;
  font-size: 13px;
  margin-left: 1rem;
  }
  .drop-tab2 button {
    display: block;
  background-color: inherit;
  color: black;
  /* padding: 20px 15px; */
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  line-height: 10px !important;
  font-size: 13px;
  margin-left: 1rem;
  
  }
  
  .drop-tab1 button Link:hover {
  
    color: #0b4a99;
  }
  
 
  .drop-tab2 button:hover {
   
    color: #0b4a99;
  }
  
 
  .drop-tabcontent {
    float: left;
  padding: 2px 12px;
  border: 1px solid #ecebf5;
  width: 45%;
  border-left: none;
  height: 390px;
  display: none;
  line-height: 12px;
  
 
  }
  .drop-tabcontent.active {
    display: block;
    background-color: #ebeff3;
    
   
  }
  .nav-drop-img{
    width: 500px;
    margin-left: 1rem;
    margin-top: 2rem;
  }

.drop-tab1 h5{
    color: #0b4a99;
    text-align: center;
    margin: 5px;
}
.drop-tab2 h5{
    color: #0b4a99;
    text-align: center;
    margin: 5px;
}