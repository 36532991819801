.contact-side{
    background-image:
    linear-gradient(to bottom, rgba(3, 7, 31, 0.52), rgba(59, 5, 8, 0.73)),
    url("../Assets//Contactus/hype-cycle-wm-inner-img1\ .jpg");
    width: 100%;
    height: 550px;
    background-size: cover;
    color: white;
    padding: 20px;
}
.contact-left-data{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content:center;
    align-items:flex-start ;
    text-align: left;
}
.address-card{
    height: 420px;
    gap: 10px;
}
.address-card p{
    margin-top: 10px;
}
.address-card h4{
    color: #0b4a99;
}
.contact-numbers{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 10px;
    border: 1px solid #d1c6c6;
    border-radius: 5px;
    background-color: #9fb4ce;
}
.contry-names p{
    font-size: 15px;
    font-weight: 800;
    padding: 0 5px;
}