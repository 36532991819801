/* About page */
.who-are-we{
    background: url(../Assets/AboutUS/about-us.jpg);
    background-size: cover;
    padding: 8rem;
}
.who-are-we-content{
    display: flex;
    flex-direction: column;
    gap: 2rem;

}
.who-are-we-content h1{
    font-size: 60px;
    font-weight: 700;
}
.who-are-we-content p{
    font-size: 18px;
}

.about-us-frame-1{
    display: flex;
    
    align-items: center;
    justify-content: center;
    text-align: left;
}
.farme-1-left{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: left;
    justify-content: center;
    align-items: flex-start;
}

.farme-1-left h1{
    color: blue;
}
.farme-1-left h1::after{
    content: "";
    display: inline-block;
    background: blue;
    width: 40px;
    height: 5px;
    
    margin-left: 8px;
}
.farme-1-left p{
    font-size: 18px;
}

.benifits-frame-2{
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 2rem;
    color: #fff;
    background: linear-gradient(#003B72,#8fbde9);

    /* background: rgb(182, 214, 245); */
    /* border: 2px solid blue; */
    padding: 1rem;
    border-radius: 10px;
    height: 140px;
}

.benifits-frame-6{
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 2rem;
    /* border: 2px solid blue; */
    /* padding: 0.8rem; */
    border-radius: 10px;
    /* height: 120px; */
}
.benifits,.what-we-do,.our-products h1{
    color: blue;
}
.benifits h1::after{
    content: "";
    display: inline-block;
    background: blue;
    width: 40px;
    height: 5px;
    
    margin-left: 8px;
}
.what-we-do h1::after{
    content: "";
    display: inline-block;
    background: blue;
    width: 40px;
    height: 5px;
    
    margin-left: 8px;
}
.our-products h1::after{
    content: "";
    display: inline-block;
    background: blue;
    width: 40px;
    height: 5px;
    
    margin-left: 8px;
}

.our-products-frame4{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.client-logos{
    width: 200px;
}

.siveo-group{
    background: url('../Assets/AboutUS/corporate_eng-BG.jpg');
}

.why-chooseus-card-container {
    width: 500px;
    height: 200px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin-top: 2rem;
  }
  
  .why-chooseus-card {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
  
  .why-chooseus-card .front-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    /* background: url(../Assets/AboutUS/Transports-&-logistics-image.jpg);
    background-size: cover; */
    
  }
  /* .why-chooseus-card .front-content::before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    
} */

.nav-tabs .nav-link.active{
    background: none !important;
    color: #fff !important;
    font-weight: 600;
    border-bottom: 5px solid rgb(255, 0, 0) !important;
    margin-top: -4px !important;
}
.nav-tabs .nav-link {
    border-bottom: 1px solid #2e0cec !important;
    color: #fff !important;
    border: none !important;
}

.solutions{
    /* background: url(../Assets/AboutUS/about-us.jpg); */
    background: #232579;
    background-size: cover;
}
.our-product-heading{
    color: #fff ;
}
  
  .why-chooseus-card .front-content p {
    font-size: 32px;
    font-weight: 700;
    opacity: 1;
    z-index: 9;
    background: #080a86;
    /* background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100% ); */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
  }
  
  .why-chooseus-card .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    background: linear-gradient(-45deg, #716ff1 0%, #85dafc 100% );
    color: #e8e8e8;
    padding: 20px;
    line-height: 1.5;
    border-radius: 5px;
    pointer-events: none;
    transform: translateX(-98%);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .why-chooseus-card .content .heading {
    font-size: 32px;
    font-weight: 700;
  }
  
  .why-chooseus-card:hover .content {
    transform: translateY(0);
  }
  
  .why-chooseus-card:hover .front-content {
    transform: translateX(-30%);
  }
  
  .why-chooseus-card:hover .front-content p {
    opacity: 0;
  }
  
  