.innovate-product-hero{
    position: relative;
    text-align: center;
    color: white;
}
.innovate-product-hero-title{
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
}
.innov-products{
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:  5rem 0;
    gap: 2rem;
    display: flex;
   
}
.mdrm-img{
    width: 90%;
    border-radius: 10px;
}
.mdrm-text{
    color: #0b4a99;
}

.innovate-dqm{
    background: url('../Assets/InnovativeProducts/iDQM/Data-Harmonization-Solution.jpg');
    background-repeat: no-repeat;
   
    background-size: cover;
    color: #fff;
    padding: 10rem;
    text-align: left;
}
.dqm-parts{
    background: #0b4a99;
    color: #fff !important;
}
.dqm-parts:hover{
    background: #fff !important;
    color: #000 !important;
}
.dqm-list, .ppr-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-top: 1rem;
}
.dqm-table{
    background: #0b4a99;
}

.innovate-ppr{
    background: url('../Assets/InnovativeProducts/ppr/piLog-preferred-records-banner-image.jpg');
    background-repeat: no-repeat;
   
    background-size: cover;
    color: #fff;
    padding: 10rem 0rem;
    text-align: center;
}
.ppr-btn-link{
    color: #000;   
    text-decoration: none;
}
.ppr-btn-link:hover{
    color: #fff;   
    text-decoration: none;
}
.btn-ppr{
    background: #fff;
    color: #000;
    border: none;
}
.feature-part{
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,24,121,1) 52%, rgba(0,212,255,1) 100%);
    color: #fff;
}

/* PPH */

.innovate-pph{
    background: url('../Assets/InnovativeProducts/pph/PiLog-Preferred-Hierarchy-Banner.jpg');
    background-repeat: no-repeat;
   
    background-size: cover;
    color: #fff;
    padding: 10rem 0rem;
    text-align: center;
}

/* / Data Quality CSS / */
.dqh-main{
    width: 100%;
    overflow: hidden;
}
.dqh {
    top: 25% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    margin-left: 16rem;
}
.innovative-dqh{
    background: url('../Assets/InnovativeProducts/dqh/Data_Quality_Hub_Banner-new.jpg');
    background-repeat: no-repeat;
   
    background-size: cover;
    color: #fff;
    padding: 5rem;
    text-align: center;
    overflow: hidden;
    width: 100%;
}

/* / Master-data-oncology CSS / */
.innovative-mdom{
    background: url('../Assets/InnovativeProducts/mdom/Master_Data_Ontology_Manager_Banner.jpg');
    background-repeat: no-repeat;
   
    background-size: cover;
    color: #fff;
    padding: 5rem;
    text-align: center;
    overflow: hidden;
    width: 100%;
}
.mdo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
}

.mdo h1 {
    margin-bottom: 10px;
}

.mdo p {
    margin: 0;

}


.buttons {
    margin-top: 20px;
    
}

.download-btn,
.readmore-btn {
    padding: 10px 20px;
    margin: 0 10px;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 0%;
    cursor: pointer;
    font-size: 15px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.download-btn {
    background-color: #ffffff;
    color: #000000;

}

.readmore-btn {
    background-color: transparent;
}

.readmore-btn:hover {
    background-color: #ffffff;
    color: #000000;
}

.para-text {
    line-height: 33px;
}

/* SPIR  */

.innovate-spir{
    background: url('../Assets/InnovativeProducts/ispir/eSPIR_Banner.jpg');
    background-repeat: no-repeat;
   
    background-size: cover;
    color: #000;
    padding: 6rem;
    text-align: left;
    overflow: hidden;
    
}
.innovate-spir-Challenge{
    background: url('../Assets/InnovativeProducts/ispir/the-challenge-bg.jpg');
    background-repeat: no-repeat;
   
    background-size: cover;
    color: #fff;
    padding: 5rem;
    text-align: left;
    overflow: hidden;
}
/* ETL */
.etl-list{
    display: flex;
  
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 1rem;
}
.etl-feature-list{
    display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-top: 1rem;
}

/* / PPO CSS / */

.ppo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    color: #000000;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: invert(1);
}

.carousel-indicators {
    margin-bottom: -0rem !important;
}

.mmt-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    gap: 1rem;
}

.form_main {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    padding: 30px 30px 30px 30px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.062);
    position: relative;
    overflow: hidden;
  }
  
  .form_main::before {
    position: absolute;
    content: "";
    width: 350px;
    height: 450px;
    background-color: rgb(209, 193, 255);
    transform: rotate(45deg);
    left: -180px;
    bottom: 30px;
    z-index: 1;
    border-radius: 30px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.082);
  }
  
  .heading {
    font-size: 2em;
    color: #2e2e2e;
    font-weight: 700;
    margin: 5px 0 10px 0;
    z-index: 2;
  }
  
  .inputContainer {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    gap: 1rem;
  }
  
  .inputIcon {
    position: absolute;
    left: 3px;
  }
  
  .inputField {
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgb(29, 27, 27);
    margin: 10px 0;
    color: black;
    font-size: .8em;
    font-weight: 500;
    box-sizing: border-box;
    padding-left: 30px;
  }
  
  .inputField:focus {
    outline: none;
    border-bottom: 2px solid rgb(199, 114, 255);
  }
  
  .inputField::placeholder {
    color: rgb(80, 80, 80);
    font-size: 1em;
    font-weight: 500;
  }
  
  #button {
    z-index: 2;
    position: relative;
    width: 50%;
    border: none;
    border-radius: 5px;
    background-color: rgb(162, 104, 255);
    height: 30px;
    color: white;
    font-size: .8em;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 10px;
    cursor: pointer;
  }
  
  #button:hover {
    background-color: rgb(126, 84, 255);
  }
  
  .forgotLink {
    z-index: 2;
    font-size: .7em;
    font-weight: 500;
    color: rgb(44, 24, 128);
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 20px;
  }
  
  